<template>
  <div>
    <v-card v-if="formObject && !attributeDialog && !conditionDialog">
      <v-card color="primary" min-height="10" flat tile class="pa-1">
        <v-card-actions class="white--text">
          <v-icon class="mr-2" color="white">mdi-form-select</v-icon>
          {{
            isEditing
              ? `Actualizando la regla "${formObject.ruleCode}"`
              : "Creando una regla"
          }}
          <v-spacer />
          <v-icon
            large
            @click="closePanel"
            class="mr-1"
            id="btn-close"
            color="white"
            >mdi-close-circle</v-icon
          >
        </v-card-actions>
      </v-card>

      <div class="px-4">
        <v-card-text class="px-6">
          <v-form v-if="formObject" v-model="valid">
            <v-card class="pa-2" flat>
              <v-card-title class="pb-6">
                Información básica
              </v-card-title>
              <v-row align="stretch" justify="start">
                <template v-for="(header, i) in headers">
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    v-if="
                      header.type === 'string' ||
                        header.type === 'number' ||
                        header.type === 'array' ||
                        header.type === 'object' ||
                        header.type === 'groupcode'
                    "
                    :key="i"
                  >
                    <v-text-field
                      :id="`input-${header.value}`"
                      v-model="formObject[header.value]"
                      type="text"
                      :label="header.text"
                      v-if="header.type === 'string'"
                      :rules="[requiredCheck(formObject[header.value], header)]"
                      outlined
                    />

                    <v-text-field
                      :id="`input-${header.value}`"
                      v-model.number="formObject[header.value]"
                      type="number"
                      :label="header.text"
                      v-if="header.type === 'number'"
                      :rules="[requiredCheck(formObject[header.value], header)]"
                      outlined
                    />
                    <v-combobox
                      :id="`input-${header.value}`"
                      v-model="formObject[header.value]"
                      :items="header.items"
                      :label="header.text"
                      :return-object="false"
                      :multiple="header.type === 'array'"
                      :rules="[requiredCheck(formObject[header.value], header)]"
                      outlined
                      v-if="
                        header.type === 'array' ||
                          header.type === 'object' ||
                          header.type === 'groupcode'
                      "
                    />
                    <v-checkbox
                      :id="`input-${header.value}`"
                      v-model="formObject[header.value]"
                      color="primary"
                      :label="
                        `${header.text}: ${
                          formObject[header.value] ? 'Si' : 'No'
                        }`
                      "
                      v-if="
                        header.type === 'boolean' &&
                          header.value.toLowerCase() !== 'status'
                      "
                    />
                  </v-col>
                </template>
                <v-col cols="12" sm="12" md="6" v-if="isEditing">
                  <v-combobox
                    id="input-country"
                    v-model="formObject.countryId"
                    :items="countrys"
                    label="País"
                    :return-object="false"
                    :rules="[(v) => !!v || 'El país es requerido']"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="isEditing">
                  <v-combobox
                    id="input-seller"
                    v-model="formObject.sellerId"
                    :items="sellers"
                    label="Seller"
                    :return-object="false"
                    :rules="[(v) => !!v || 'El seller es requerido']"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="isEditing">
                  <v-combobox
                    id="input-consumer"
                    v-model="formObject.consumerId"
                    :items="consumers"
                    label="Consumer"
                    :return-object="false"
                    :rules="[(v) => !!v || 'El consumer es requerido']"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card>

            <v-card class="pa-2" flat>
              <v-card-title>
                Atributos
                <v-spacer />
                <v-btn
                  @click="openAttribute({}, false)"
                  color="primary"
                  fab
                  small
                  elevation="2"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-title>
              <v-card outlined class="pa-4">
                <v-row align="stretch" justify="start">
                  <v-card-text v-if="formObject.attributes.length === 0">
                    No se encontraron atributos asociados a esta regla
                  </v-card-text>
                  <v-chip
                    v-for="(attribute, i) in formObject.attributes"
                    :key="i"
                    class="ma-2"
                    color="primary"
                    @click="openAttribute(attribute, true)"
                  >
                    {{ getTextForAttributes(attribute.attributeCode) }}
                  </v-chip>
                </v-row>
              </v-card>
            </v-card>

            <v-card class="pa-2" flat>
              <v-card-title>
                Condiciones de productos
                <v-spacer />
                <v-btn
                  @click="openCondition({}, false)"
                  color="primary"
                  fab
                  small
                  elevation="2"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-title>
              <v-card outlined class="pa-4">
                <v-row align="stretch" justify="start">
                  <v-card-text v-if="formObject.productConditions.length === 0">
                    No se encontraron condiciones de productos asociados a esta
                    regla
                  </v-card-text>
                  <v-chip
                    v-for="(condition, i) in formObject.productConditions"
                    :key="i"
                    class="ma-2"
                    color="primary"
                    @click="openCondition(condition, true)"
                  >
                    {{ getTextForPCondition(condition.conditionCode) }}
                  </v-chip>
                </v-row>
              </v-card>
            </v-card>

            <v-card class="pa-2" flat>
              <v-card-title>
                Estado de la regla
              </v-card-title>
              <v-radio-group v-model="formObject.ruleStatus.ruleStatusCode" row>
                <v-radio id="radiobtn-a" value="A" label="Aceptado" />
                <v-radio id="radiobtn-r" value="R" label="Rechazado" />
                <v-radio id="radiobtn-pa" value="PA" label="Preaprobado" />
                <v-radio
                  id="radiobtn-rst"
                  value="RST"
                  label="Requiere Servicio Técnico"
                />
              </v-radio-group>
            </v-card>

            <v-card class="pa-2" flat>
              <v-card-title>
                Periodo de válidez
              </v-card-title>
              <v-row align="stretch" justify="center">
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    v-model="initDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formObject.effectiveDate"
                        label="Fecha de inicio"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="[
                          (v) => !!v || 'Debe seleccionar una fecha de inicio',
                          (v) => validateInitDate(v),
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formObject.effectiveDate"
                      no-title
                      scrollable
                      @input="initDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formObject.expireDate"
                        label="Fecha de expiración"
                        prepend-icon="mdi-calendar"
                        :rules="[(v) => validateEndDate(v)]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formObject.expireDate"
                      no-title
                      scrollable
                      @input="endDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-2 pb-6 pt-12">
          <v-spacer />
          <v-btn @click="closePanel" class="mr-2" depressed color="transparent"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            @click="isEditing ? updateObject() : saveObject()"
            :disabled="!valid"
          >
            {{ isEditing ? "Actualizar" : "Crear" }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <div v-if="attributeDialog">
      <AttributeForm
        :object.sync="dialogObject"
        :currentAttributes="formObject.attributes"
        :isEditing="isSubEditing"
        :currentTimezone="currentTimezone"
        @updateAttribute="updateAttribute"
        @addAttribute="addAttribute"
        @ClosePanel="attributeDialog = false"
      />
    </div>
    <div v-if="conditionDialog">
      <ConditionForm
        :object.sync="dialogObject"
        :currentConditions="formObject.productConditions"
        :isEditing="isSubEditing"
        :currentTimezone="currentTimezone"
        @updateCondition="updateCondition"
        @addCondition="addCondition"
        @ClosePanel="conditionDialog = false"
      />
    </div>
  </div>
</template>

<script>
import attributes from "@/assets/json/attributes.json";
import productCondition from "@/assets/json/productCondition.json";
import baseForm from "@/shared/utils/Form";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "rule-form",
  props: ["currentTimezone"],
  components: {
    AttributeForm: () => import("./AttributeForm"),
    ConditionForm: () => import("./ConditionForm"),
  },
  data: () => ({
    dialog: false,
    dialogObject: null,
    attributeDialog: false,
    conditionDialog: false,
    isSubEditing: false,
    initDateMenu: false,
    endDateMenu: false,
  }),
  mixins: [baseForm],
  watch: {
    dialog(val) {
      if (!val) {
        this.conditionDialog = false;
        this.attributeDialog = false;
      }
    },
  },
  mounted() {
    if (this.currentTimezone) {
      dayjs.tz.setDefault(this.currentTimezone);
    }
    this.formObject.attributes = this.formObject.attributes || [];
    this.formObject.ruleStatus = this.formObject.ruleStatus || {
      ruleStatusCode: "",
    };
    this.formObject.productConditions = this.formObject.productConditions || [];
    if (typeof this.formObject.expireDate === "string") {
      this.formObject.expireDate = this.formObject?.expireDate?.substring(
        0,
        10
      );
    }
    if (typeof this.formObject.effectiveDate === "string") {
      this.formObject.effectiveDate = this.formObject?.effectiveDate?.substring(
        0,
        10
      );
    } else {
      this.formObject.effectiveDate = dayjs()
        .add(1, "day")
        .format("YYYY-MM-DD");
    }
    if (this.isEditing) {
      this.parseTextToObject();
    }
  },
  methods: {
    validateEndDate(v) {
      if (!v) return true;
      if (!this.formObject.effectiveDate) return true;
      const effectiveDate = dayjs(this.formObject.effectiveDate);
      const expireDate = dayjs(v);
      if (expireDate.isSame(effectiveDate))
        return "Ambas fechas no pueden ser las mismas";
      return (
        expireDate.isAfter(effectiveDate) ||
        "La fecha de expiración no puede ser antes de la fecha de inicio"
      );
    },
    validateInitDate(v) {
      if (!v) return true;
      if (!this.formObject.expireDate) return true;
      const effectiveDate = dayjs(v);
      const expireDate = dayjs(this.formObject.expireDate);
      if (expireDate.isSame(effectiveDate))
        return "Ambas fechas no pueden ser las mismas";
      return (
        effectiveDate.isBefore(expireDate) ||
        "La fecha de inicio no puede ser después de la fecha de expiración"
      );
    },
    openAttribute(object, isEditing) {
      this.isSubEditing = isEditing;
      this.dialogObject = Object.assign({}, object);
      this.attributeDialog = true;
    },
    openCondition(object, isEditing) {
      this.isSubEditing = isEditing;
      this.dialogObject = Object.assign({}, object);
      this.conditionDialog = true;
    },
    addAttribute(v) {
      this.formObject.attributes.push(v);
      this.dialogObject = null;
      this.attributeDialog = false;
    },
    updateAttribute(v) {
      this.formObject.attributes = this.formObject.attributes.map(
        (attribute) => {
          if (attribute.attributeCode === v.attributeCode) {
            attribute = Object.assign({}, v);
          }
          return attribute;
        }
      );
      this.dialogObject = null;
      this.attributeDialog = false;
    },
    addCondition(v) {
      this.formObject.productConditions.push(v);
      this.dialogObject = null;
      this.conditionDialog = false;
    },
    updateCondition(v) {
      this.formObject.productConditions = this.formObject.productConditions.map(
        (condition) => {
          if (condition.conditionCode === v.conditionCode) {
            condition = Object.assign({}, v);
          }
          return condition;
        }
      );
      this.dialogObject = null;
      this.conditionDialog = false;
    },
    getTextForPCondition(val) {
      let res = null;
      productCondition.forEach((condition) => {
        if (condition.code === val) {
          res = condition.text;
        }
      });
      return res;
    },
    getTextForAttributes(val) {
      let res = null;
      attributes.forEach((attribute) => {
        if (attribute.attributeCode === val) {
          res = attribute.text;
        }
      });
      return res;
    },
  },
};
</script>
