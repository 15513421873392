<template>
  <div></div>
</template>

<script>
/**
 * Base logic of Forms components
 * @author Cristian Molina
 */
import sellerJson from "@/assets/json/sellers";
import consumersJson from "@/assets/json/consumers";
import countryJson from "@/assets/json/countrys";

export default {
  name: "util-forms",
  props: ["headers", "items", "object", "isEditing"],
  data: () => ({
    formObject: null,
    valid: false,
    sellers: [],
    consumers: [],
    countrys: [],
    backupFormObject: null,
  }),
  watch: {
    /**
     * @description Watch of object props params
     * @param {any} v
     */
    object(v) {
      if (v) {
        this.formObject = Object.assign({}, v);
      } else {
        this.formObject = null;
      }
    },
  },
  /**
   * @description Mounted instance of vue component
   */
  mounted() {
    this.sellers = [...sellerJson];
    this.consumers = [...consumersJson];
    this.countrys = [...countryJson];
    this.formObject = Object.assign({}, this.object);
    this.backupFormObject = Object.assign({}, this.formObject);
  },
  methods: {
    /**
     * @description Parse object properties to Object for Combobox
     * @author Cristian Molina
     */
    parseTextToObject() {
      console.log(this.formObject);
      this.sellers.forEach((val) => {
        if (val.value === this.formObject.sellerId) {
          this.formObject.sellerId = val;
        }
      });
      this.consumers.forEach((val) => {
        if (val.value === this.formObject.consumerId) {
          this.formObject.consumerId = val;
        }
      });
      this.countrys.forEach((val) => {
        if (val.value === this.formObject.countryId) {
          this.formObject.countryId = val;
        }
      });
    },

    /**
     * @description Check if items exists in table and is duplicated
     * @param {any} v object to check in table
     * @returns {boolean} true or false
     * @author Cristian Molina
     */
    hasDuplicated(v) {
      if (!v) return true;
      let exist = true;
      this.items.forEach((item) => {
        Object.keys(v).forEach((key) => {
          if (item[key] === v[key]) {
            exist = false;
          }
        });
      });
      return exist || "Se ha encontrado coincidencia con datos ya almacenados";
    },
    /**
     * @description Check if field is required in form.
     * @param {any} v object to check if required
     * @param {any} header current header object loaded
     * @returns {boolean} true or false
     * @author Cristian Molina
     */
    requiredCheck(v, header) {
      if (header.type === "number" && v === 0) return true;
      if (header.required) {
        return !!v || `Campo ${header.text.toLowerCase()} es requerido`;
      } else {
        return true;
      }
    },
    /**
     * @description Close panel with event to parent component
     * @emits closePanel event
     * @author Cristian Molina
     */
    closePanel() {
      this.$emit("ClosePanel");
    },
    /**
     * @description Send object with event to parent component with purpose of save in current object.
     * @author Cristian Molina
     */
    updateObject() {
      this.$emit("UpdateObject", this.formObject);
    },
    /**
     * @description Send object with event to parent component with purpose of save this.
     * @author Cristian Molina
     */
    saveObject() {
      this.$emit("SaveObject", this.formObject);
    },
  },
};
</script>
